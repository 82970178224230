import { css } from '@emotion/core';
import { theme } from '../../layouts/main.layout';

export const arrow = css`
  transform: rotate(90deg);
`;

export const linkStyle = css`
  color: ${theme.colors.primaryTextGray};
`;
