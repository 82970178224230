import React from 'react';

import { StandardPageLayout } from '../../layouts/StandardPage.layout';
import { PageSection } from '../../components/layout/_/PageSection/PageSection.component';
import { useTranslation } from 'react-i18next';
import SectionHeader from '../../components/layout/SectionHeader/SectionHeader.component';
import { Box, Flex, Image, Text } from 'rebass';
import { Link } from 'gatsby';
import { Container, theme } from '../../layouts/main.layout';
import { arrow, linkStyle } from './career.css';
import { positions } from './positions/positions-data';
import { NewStandardPageLayout } from '../../layouts/newStadardPage.layout';

const CareerPage: React.FC = () => {
  const { t } = useTranslation();

  const meta = {
    title: t('meta_CareerTitle'),
    description: t('meta_CareerDescription'),
  };

  return (
    <NewStandardPageLayout
      fullWidth={true}
      mainHeading={t('career_MainHeading')}
      backgroundImage="bg/career@3x.jpg"
      backgroundImageMobile="bg/career-mobile@3x.jpg"
      og={{
        image: '/img/meta-img/career-meta-og-2-0-new.jpeg',
      }}
      meta={meta}
    >
      <Container style={{ padding: '120px 0' }}>
        <SectionHeader title={t('career_Heading')} />
        <Text textAlign="center">{t('career_SubHeading')}</Text>
        <Box mt={6}>
          <Text>{t('career_Title')}</Text>
          {positions.map((position: any) => (
            <Link key={position.url} to={`/sr/career/${position.url}`} css={linkStyle}>
              <Flex mt={4} alignItems="center" justifyContent="space-between" width={{ _: 'auto', sm: '750px' }}>
                <Box>
                  <Text fontSize={theme.fontSizes[5]}>{position.title}</Text>
                  <Text>{position.subTitle}</Text>
                </Box>
                <Box css={arrow} ml={{ _: 'unset', sm: '150px' }}>
                  <Image src="/img/arrow-black@2x.png" />
                </Box>
              </Flex>
            </Link>
          ))}

          <Flex mt={5}>
            <Box>
              <Text fontSize={theme.fontSizes[5]}>Niste pronašli poziciju koja Vam odgovara?</Text>
              <Text>
                To nije razlog za brigu. Pošaljite nam svoju biografiju na{' '}
                <a style={{ textDecoration: 'underline' }} href="mailto:hr@vozzi.eu">
                  hr@vozzi.eu
                </a>{' '}
                i mi ćemo Vas kontaktirati čim se odgovarajuća pozicija otvori.
              </Text>
            </Box>
          </Flex>
        </Box>
      </Container>
    </NewStandardPageLayout>
  );
};

export default CareerPage;
